/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          /*  jQuery Nice Select - v1.1.0
      https://github.com/hernansartorio/jquery-nice-select
      Made by Hernán Sartorio  */

          (function($) {

              $.fn.niceSelect = function(method) {

                  // Methods
                  if (typeof method == 'string') {
                      if (method == 'update') {
                          this.each(function() {
                              var $select = $(this);
                              var $dropdown = $(this).next('.nice-select');
                              var open = $dropdown.hasClass('open');

                              if ($dropdown.length) {
                                  $dropdown.remove();
                                  create_nice_select($select);

                                  if (open) {
                                      $select.next().trigger('click');
                                  }
                              }
                          });
                      } else if (method == 'destroy') {
                          this.each(function() {
                              var $select = $(this);
                              var $dropdown = $(this).next('.nice-select');

                              if ($dropdown.length) {
                                  $dropdown.remove();
                                  $select.css('display', '');
                              }
                          });
                          if ($('.nice-select').length == 0) {
                              $(document).off('.nice_select');
                          }
                      } else {
                          console.log('Method "' + method + '" does not exist.')
                      }
                      return this;
                  }

                  // Hide native select
                  this.hide();

                  // Create custom markup
                  this.each(function() {
                      var $select = $(this);

                      if (!$select.next().hasClass('nice-select')) {
                          create_nice_select($select);
                      }
                  });

                  function create_nice_select($select) {
                      $select.after($('<div></div>')
                          .addClass('nice-select')
                          .addClass($select.attr('class') || '')
                          .addClass($select.attr('disabled') ? 'disabled' : '')
                          .attr('tabindex', $select.attr('disabled') ? null : '0')
                          .html('<span class="current"></span><ul class="list"></ul>')
                      );

                      var $dropdown = $select.next();
                      var $options = $select.find('option');
                      var $selected = $select.find('option:selected');

                      $dropdown.find('.current').html($selected.data('display') || $selected.text());

                      $options.each(function(i) {
                          var $option = $(this);
                          var display = $option.data('display');

                          $dropdown.find('ul').append($('<li></li>')
                              .attr('data-value', $option.val())
                              .attr('data-display', (display || null))
                              .addClass('option' +
                                  ($option.is(':selected') ? ' selected' : '') +
                                  ($option.is(':disabled') ? ' disabled' : ''))
                              .html($option.text())
                          );
                      });
                  }

                  /* Event listeners */

                  // Unbind existing events in case that the plugin has been initialized before
                  $(document).off('.nice_select');

                  // Open/close
                  $(document).on('click.nice_select', '.nice-select', function(event) {
                      var $dropdown = $(this);

                      $('.nice-select').not($dropdown).removeClass('open');
                      $dropdown.toggleClass('open');

                      if ($dropdown.hasClass('open')) {
                          $dropdown.find('.option');
                          $dropdown.find('.focus').removeClass('focus');
                          $dropdown.find('.selected').addClass('focus');
                      } else {
                          $dropdown.focus();
                      }
                  });

                  // Close when clicking outside
                  $(document).on('click.nice_select', function(event) {
                      if ($(event.target).closest('.nice-select').length === 0) {
                          $('.nice-select').removeClass('open').find('.option');
                      }
                  });

                  // Option click
                  $(document).on('click.nice_select', '.nice-select .option:not(.disabled)', function(event) {
                      var $option = $(this);
                      var $dropdown = $option.closest('.nice-select');

                      $dropdown.find('.selected').removeClass('selected');
                      $option.addClass('selected');

                      var text = $option.data('display') || $option.text();
                      $dropdown.find('.current').text(text);

                      $dropdown.prev('select').val($option.data('value')).trigger('change');
                  });

                  // Keyboard events
                  $(document).on('keydown.nice_select', '.nice-select', function(event) {
                      var $dropdown = $(this);
                      var $focused_option = $($dropdown.find('.focus') || $dropdown.find('.list .option.selected'));

                      // Space or Enter
                      if (event.keyCode == 32 || event.keyCode == 13) {
                          if ($dropdown.hasClass('open')) {
                              $focused_option.trigger('click');
                          } else {
                              $dropdown.trigger('click');
                          }
                          return false;
                          // Down
                      } else if (event.keyCode == 40) {
                          if (!$dropdown.hasClass('open')) {
                              $dropdown.trigger('click');
                          } else {
                              var $next = $focused_option.nextAll('.option:not(.disabled)').first();
                              if ($next.length > 0) {
                                  $dropdown.find('.focus').removeClass('focus');
                                  $next.addClass('focus');
                              }
                          }
                          return false;
                          // Up
                      } else if (event.keyCode == 38) {
                          if (!$dropdown.hasClass('open')) {
                              $dropdown.trigger('click');
                          } else {
                              var $prev = $focused_option.prevAll('.option:not(.disabled)').first();
                              if ($prev.length > 0) {
                                  $dropdown.find('.focus').removeClass('focus');
                                  $prev.addClass('focus');
                              }
                          }
                          return false;
                          // Esc
                      } else if (event.keyCode == 27) {
                          if ($dropdown.hasClass('open')) {
                              $dropdown.trigger('click');
                          }
                          // Tab
                      } else if (event.keyCode == 9) {
                          if ($dropdown.hasClass('open')) {
                              return false;
                          }
                      }
                  });

                  // Detect CSS pointer-events support, for IE <= 10. From Modernizr.
                  var style = document.createElement('a').style;
                  style.cssText = 'pointer-events:auto';
                  if (style.pointerEvents !== 'auto') {
                      $('html').addClass('no-csspointerevents');
                  }

                  return this;

              };

          }(jQuery));
      },
      finalize: function() {

        $('.back-to-top').click(function(){
          $("html, body").animate({ scrollTop: 0 }, 500);
          return false;
        });

          $('select').niceSelect();



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
